<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; text-align:center;"
            class="intro-header">
            <div class="header-text">
                <div style="color: #333333; font-size: 32px; font-weight: bold;" class="header-title">
                    免责声明
                </div>
                <div style="color: #999999;">
                    Disclaimers
                </div>
            </div>
        </div>
        <div style="padding: 0px 18px; width: 1200px; margin: 0 auto; ">
            <div style="color: #3D3D3D; font-size: 24px;  padding-bottom: 16px; border-bottom: 1px solid #eeeeee; margin-top: 40px; margin-bottom: 44px;">
                免责声明
            </div>
            <div>
                <p>本免责声明(以下简称&ldquo;声明&rdquo;)适用于广州市科颐轩电子科技有限公司(以下简称&ldquo;我们&rdquo;)旗下的产品（或服务）。若您已经开始或正在使用我们的产品（或服务），即表示您同意接受本声明各项条款的约束。
                </p>
                <p>　　1
                    本软件经过详细的测试，但不能保证与所有的软硬件系统完全兼容，不能保证本软件完全没有错误。对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。如果出现不兼容及软件错误的情况，您可登录通过科颐轩官网的&ldquo;联系我们&rdquo;中获得联系方式，获得技术支持。
                </p>
                <p>　　2
                    使用本软件产品风险由您自行承担，在适用法律允许的最大范围内，对因使用或不能使用本软件所产生的损害及风险，包括但不限于直接或间接的个人损害、商业赢利的丧失、贸易中断、商业信息的丢失或任何其它经济损失，我们不承担任何责任。
                </p>
                <p>　　3
                    对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务，我们不承担任何责任。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import BGImage from '@/assets/ball-background.png';
export default {
    data() {
        return {
            BGImage
        }
    }
}
</script>

<style lang="less" scoped>
.intro-header {
    width: 100%;
    height: 250px;
    width: 100%;
    background: url(../../../assets/ball-background.png);
}
</style>